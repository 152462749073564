import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import LinkTo from "./elements/LinkTo";
import styled from "@emotion/styled";
import color, { themeColors } from "../styles/colors";
import { h3, button } from "../styles/typography";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import {
	spacingRestricted,
	screen,
	container,
	grid,
	gridColumnByMargin,
	screenMax,
} from "../styles/mixins";
import Button from "./elements/Button";
import { ReactComponent as Logo } from "../images/vectors/logo.svg";
import { ReactComponent as BurgerSvg } from "../images/vectors/burger.svg";
import { ReactComponent as Cross } from "../images/vectors/close.svg";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { withPrefix } from "gatsby"

const HeaderEl = styled.header`
	position: sticky;
	top: -1px;
	z-index: 100;
	width: 100%;
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	color: ${color("white")};
`;

const HeaderBar = styled.div`
	position: relative;
	z-index: 100;
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	padding-top: 20px;
	padding-bottom: 20px;

	@media ${screen("ml")} {
		padding-top: 0;
		padding-bottom: 0;
	}
`;

const ContainerEl = styled.div`
	${container()};
	pointer-events: none;
`;

const HeaderBarContent = styled.div`
	grid-column: 1 / span 48;
	align-items: center;
	${grid()};

	* {
		pointer-events: auto;
	}

	@media ${screen("ml")} {
		${gridColumnByMargin(2)}
		display: flex;
	}
`;

const LogoWrapper = styled.div`
	grid-column: 3 / span 12;

	svg {
		max-width: 100%;
		height: auto;
		width: 93px;
	}

	@media ${screen("ml")} {
		svg {
			max-width: 100%;
			height: auto;
			width: 125px;
		}
	}

	@media ${screen("lg")} {
		display: flex;
	}
`;

const NavMobilePopoutHeader = styled.div`
	grid-column: 1 / span 48;
	${grid()};
	align-items: center;
	padding-top: 22px;
	padding-bottom: 22px;

	@media ${screen("ml")} {
		display: none;
	}
`;

const CloseWrapper = styled.div`
	grid-column: 20 / span 26;
	text-align: right;
	margin: -10px;
`;

const Close = styled.button`
	width: 35px;
	padding: 10px;

	&:focus {
		outline: none;
	}

	&.dark {
		svg {
			path {
				stroke: ${color("purple")};
			}
		}
	}
`;

const NavPrimary = styled.div`
	grid-column: 5 / span 40;
	justify-self: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	${grid()};
	grid-template-rows: min-content auto min-content min-content;
	transform: translateX(${(props) => (props.shown ? 0 : "100%")});
	visibility: ${(props) => (props.shown ? "visible" : "hidden")};
	will-change: transform;
	transition: transform 0.3s, visibility 0.7s;
	overflow-y: auto;
	z-index: 1;

	@media ${screen("ml")} {
		display: block;
		position: static;
		background-color: none;
		overflow-y: hidden;
		transform: none;
		visibility: visible;
		width: auto;
		flex-grow: 1;
		${spacingRestricted("padding-left", "m")};
		${spacingRestricted("padding-right", "m")};
		transition: none;
		z-index: 0;
	}

	@media ${screen("lg")} {
		flex: 1;
		display: flex;
		justify-content: center;
		flex-grow: 3;
	}
`;

const PoweredWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	font-size: 12px;
	line-height: 16px;
	margin-top: 6em;
	white-space: nowrap;
	grid-column: 23 / span 24;
`;

const PoweredImage = styled(Image)`
	width: 95px;
	margin-left: 1em;
`

const NavPrimaryLinks = styled.ul`
	display: flex;
	flex-direction: column;
	height: 100%;
	grid-column: 3 / span 44;
	padding-top: 45px;
	padding-bottom: 35px;

	@media ${screen("ml")} {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding-top: 0;
		padding-bottom: 0;
	}
`;

const NavLink = styled(Link)`
	${spacingRestricted("padding-top", "xs")};
	${spacingRestricted("padding-bottom", "xs")};
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-decoration: none;
	font-size: 30px;
	line-height: 1.25;
	font-weight: 500;

	@media ${screen("md")} {
		font-size: 18px;
		font-weight: 400;
	}

	svg {
		height: 0.63em;

		path {
			stroke: ${color("white")};
		}
	}

	@media ${screen("ml")} {
		padding: 0;
		${spacingRestricted("margin-top", "m")};
		${spacingRestricted("margin-bottom", "m")};
		text-align: center;

		span {
			${button};
			padding: 0.65em 1em;
			display: block;
			border-radius: 10em;
			border: 1px solid transparent;
			transition: border-color 0.2s;
			font-weight: inherit;
		}

		svg {
			display: none;
		}

		&.active,
		&:hover {
			span {
				background-color: ${color("light-grey")};
			}
		}
	}

	@media ${screen("lg")} {
		${spacingRestricted("margin-left", "xxs")};
		${spacingRestricted("margin-right", "xxs")};
	}

	@media ${screen("ms")} {
		span {
			padding: 0.65em 2em;
		}
	}
`;

const NavMobilePrimaryCta = styled.div`
	grid-column: 3 / span 44;
	margin-bottom: 50px;

	a {
		width: 100%;
	}

	@media ${screen("ml")} {
		display: none;
	}
`;

const NavMobilePrimaryFooter = styled.div`
	grid-column: 1 / span 48;
	${grid()};
	background-color: ${color("light-grey")};
	padding-top: 35px;
	padding-bottom: 35px;
	border-top-right-radius: 66px;

	ul {
		grid-column: 3 / span 20;
		margin: -12px -9px;

		li {
			margin: 12px 9px;
		}
	}

	@media ${screen("ml")} {
		display: none;
	}
`;

const CtaWrapper = styled.div`
	grid-column: 18 / 47;
	justify-self: end;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: stretch;

	@media ${screen("ml")} {
		white-space: nowrap;
	}

	@media ${screen("lg")} {
		display: flex;
		justify-content: flex-end;
	}
`;

const ApplyLink = styled(Button)`
	@media ${screenMax("ml")} {
		padding: 0.75em 2em;
	}
`;

const Burger = styled.button`
	width: 31px;
	margin-left: 15px;

	&:focus {
		outline: none;
	}

	@media ${screen("ml")} {
		display: none;
	}
`;

const Header = ({ colorTheme = "dark" }) => {
	const [navOpen, setNavOpen] = useState(false);

	const headerBarRef = useRef();
	const navPrimaryRef = useRef();

	const handleBurgerClick = () => {
		setNavOpen(true);
	};

	const handleCloseClick = () => {
		setNavOpen(false);
	};

	const handleResize = () => {
		if (window.innerWidth >= 768) {
			setNavOpen(false);
		}
	};

	const shouldAllowTouchMove = (el) => {
		while (el && el !== document.body) {
			if (el.dataset["body-scroll-lock-ignore"] !== null) {
				return true;
			}

			el = el.parentElement;
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			enableBodyScroll(navPrimaryRef.current);
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (navOpen) {
			disableBodyScroll(navPrimaryRef.current, {
				allowTouchMove: shouldAllowTouchMove,
				reserveScrollBarGap: true
			});
		} else {
			enableBodyScroll(navPrimaryRef.current);
		}
	}, [navOpen]);

	const {
		mtfFinanceLogo,
	} = useStaticQuery(graphql`
		query headerContent {
			mtfFinanceLogo: file(relativePath: { eq: "logos/mtf-finance-logo.png" }) {
				childImageSharp {
					fluid {
						aspectRatio
						base64
						src
						srcSet
						sizes
					}
				}
			}
		}
	`);

	return (
		<HeaderEl colorTheme={colorTheme}>
			<HeaderBar ref={headerBarRef} colorTheme={colorTheme}>
				<ContainerEl>
					<HeaderBarContent>
						<LogoWrapper>
							<LinkTo aria-label="Home" url="/">
								<Logo />
							</LinkTo>
						</LogoWrapper>
						<NavPrimary
							colorTheme={colorTheme}
							shown={navOpen}
							ref={navPrimaryRef}>
							<NavMobilePopoutHeader>
								<LogoWrapper>
									<LinkTo aria-label="Home" url="/">
										<Logo />
									</LinkTo>
								</LogoWrapper>
								<CloseWrapper>
									<Close onClick={handleCloseClick}>
										<Cross />
									</Close>
								</CloseWrapper>
							</NavMobilePopoutHeader>
							<NavPrimaryLinks>
								<li>
									<NavLink to="/personal-loans" activeClassName="active">
										<span>Personal Loans</span>
									</NavLink>
								</li>
								<li>
									<NavLink to="/personal-loans/vehicle-finance/" activeClassName="active">
										<span>Vehicle Finance</span>
									</NavLink>
								</li>
								<li>
									<NavLink to="/personal-loans/debt-consolidation-loans/" activeClassName="active">
										<span>Debt Consolidation</span>
									</NavLink>
								</li>
								{/*
								<li>
									<NavLink to="/home-loans/" activeClassName="active">
										<span>Home Loans</span>
									</NavLink>
								</li>
								*/}
								<li>
									<NavLink to="/guides-insights" activeClassName="active">
										<span>Guides &amp; Insights</span>
									</NavLink>
								</li>
							</NavPrimaryLinks>
							<NavMobilePrimaryCta>
								<Button
									url={`https://apply.thelendingpeople.co.nz/loan-type`}
									external={true}
									data-event-name="select_cta"
									data-content-type="Apply now"
									data-content-destination={`https://apply.thelendingpeople.co.nz/loan-type`}
									data-content-text="Apply now">
									APPLY NOW
								</Button>
							</NavMobilePrimaryCta>
							<NavMobilePrimaryFooter>
								<ul>
									<li>
										<LinkTo url="/help-support">Help & Support</LinkTo>
									</li>
									<li>
										<LinkTo url="/trust-center">Important Info</LinkTo>
									</li>
									<li>
										<LinkTo url="/personal-loans/#calculator">Calculators</LinkTo>
									</li>
									<li>
										<LinkTo url="/about-us/why-lending-people">About Us</LinkTo>
									</li>
								</ul>
								<PoweredWrapper>
									Powered by
									<LinkTo url="https://www.mtf.co.nz/" target="_blank">
										{mtfFinanceLogo && (
											<PoweredImage fluid={mtfFinanceLogo.childImageSharp.fluid} />
										)}
									</LinkTo>
								</PoweredWrapper>
							</NavMobilePrimaryFooter>
						</NavPrimary>
						<CtaWrapper>
							<ApplyLink
								url={`https://apply.thelendingpeople.co.nz/loan-type`}
								external={true}
								data-event-name="select_cta"
								data-content-type="Apply now"
								data-content-destination={`https://apply.thelendingpeople.co.nz/loan-type`}
								data-content-text="Apply now"
								size="small">
								APPLY NOW
							</ApplyLink>
							<Burger aria-label="Main Menu" onClick={handleBurgerClick}>
								<BurgerSvg />
							</Burger>
						</CtaWrapper>
					</HeaderBarContent>
				</ContainerEl>
			</HeaderBar>
		</HeaderEl>
	);
};

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
